import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Col, notification, Row } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CLOSE_ICON from '../../../../assets/images/close-success.png'
import ERROR from '../../../../assets/images/error.png'
import SUCCESS from '../../../../assets/images/success.png'
import Loading from '../../../../components/Common/Loading/Loading'
import clientWarehouseApi from '../../../../http/clientWarehouseApi'
import { Client, ClientCompanyWareHouse } from '../../../../models'
import { CommonError } from '../../../../models/common/error'
import ClientMainSection, {
  ClientMainSectionStateInterface,
} from './ClientMainSection'
import Gestion, { ClientGestionStateInterface } from './Gestion'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { focusById } from '../../../../hook/usePressTab'
import './../Client.scss'
import {
  getRemoveTriangles,
  getTheChangeOfClientData,
  mapDataFromApiToState,
  mapIdToTriangles,
} from './func'
import { MODE } from '../../../../enum/mode'
import CustomPopup from '../../../../components/Common/CustomPopup/CustomPopup'
import { popupType } from '../../../../enum/popupType'
import { t } from 'i18next'
import { isObjectEmpty } from '../../../../utils/objectUtils'
import accessoriesApi from '../../../../http/accessoriesApi'

const ClientEdit = () => {
  const { id } = useParams()
  const clientKey = useSelector((state: RootState) => state.selector).data
    .client
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initData, setInitData] = useState<Client>()
  const [mainSectionDataInit, setMainSectionDataInit] =
    useState<ClientMainSectionStateInterface>()

  const [mainSectionData, setMainSectionData] =
    useState<ClientMainSectionStateInterface>()
  const [mainSectionNonEidtOptionData, setMainSectionNonEidtOptionData] =
    useState<ClientCompanyWareHouse[]>()

  const [gestionDataInit, setGestionDataInit] =
    useState<ClientGestionStateInterface>()

  const [gestionData, setGestionData] = useState<ClientGestionStateInterface>()
  const [errorMainSection, setErrorMainSection] = useState<CommonError[]>([])
  const [errorClientGestion, setErrorClientGestion] = useState<CommonError[]>(
    []
  )
  const [isValidMainSection, setIsValidMainSection] = useState<boolean>(false)
  const [isValidGestionSection, setIsValidGestionSection] =
    useState<boolean>(false)
  const isValidAll = isValidMainSection && isValidGestionSection
  const [isAllowDel, setIsAllowDel] = useState(false)
  const [openPopupDelete, setOpenPopupDelete] = useState(false)
  const [dataFromApi, setDataFromApi] = useState<{
    mainSectionData?: ClientMainSectionStateInterface
    gestionData?: ClientGestionStateInterface
  }>({})

  useEffect(() => {
    if (!id) return
    setIsLoading(true)
    Promise.all([
      clientWarehouseApi.getClientByID(id),
      accessoriesApi.getCompanyWarehouseByClientId(id, { is_block: true }),
    ])
      .then((values) => {
        const allData = values[0].data.entry
        const nonEditData = values[1].data.entry

        setInitData(allData)
        const { mainSectionData, gestionData } = mapDataFromApiToState(allData)
        setMainSectionDataInit(mainSectionData)
        setMainSectionData(mainSectionData)
        setMainSectionNonEidtOptionData(nonEditData)

        setGestionData(gestionData)
        setGestionDataInit(gestionData)

        setIsAllowDel(Boolean(allData.is_del))
      })
      .finally(() => setIsLoading(false))
  }, [id])

  useEffect(() => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    if (mainSectionData?.code) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(
            item.name === 'code' &&
            (item.errorMessage === 'Code déjà existant' ||
              item.errorMessage === ' ')
          )
      )
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) =>
          !(item.name === 'code' && item.errorMessage === 'Code déjà existant')
      )
    }
    if (mainSectionData?.nom) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    if (mainSectionData?.email) {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'email' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSectionData])

  useEffect(() => {
    let errorClientGestionClone = cloneDeep(errorClientGestion)

    if (gestionData?.mois_dluo_input || !gestionData?.mois_dluo_activate) {
      errorClientGestionClone = errorClientGestionClone.filter(
        (item) =>
          !(item.name === 'mois_dluo_input' && item.errorMessage === ' ')
      )
    }
    setErrorClientGestion(errorClientGestionClone)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gestionData])

  const onCancelModal = () => {
    navigate(
      `/configurations/client?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const onChangeDataMainSection = (data: ClientMainSectionStateInterface) => {
    setMainSectionData(data)
  }

  const onChangeGestion = (data: ClientGestionStateInterface) => {
    setGestionData(data)
  }

  const onCheckValidMainSection = (isValid: boolean) => {
    setIsValidMainSection(isValid)
  }

  const onCheckValidGestionSection = (isValid: boolean) => {
    setIsValidGestionSection(isValid)
  }

  const onDeleteClient = () => {
    if (!id) return
    setIsLoading(true)
    clientWarehouseApi
      .deleteClient(id)
      .then((res) => {
        navigate(-1)
      })
      .catch((e) => {
        showErrorMessage()
      })
      .finally(() => {
        setOpenPopupDelete(false)
        setIsLoading(false)
      })
  }
  const onSubmit = () => {
    let errorMainSectionClone = cloneDeep(errorMainSection)
    let errorClientGestionClone = cloneDeep(errorClientGestion)
    if (!mainSectionData?.code) {
      errorMainSectionClone.push({
        name: 'code',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'code' && item.errorMessage !== ' '
      )
    }
    if (!mainSectionData?.nom) {
      errorMainSectionClone.push({
        name: 'nom',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'nom' && item.errorMessage !== ' '
      )
    }
    if (!mainSectionData?.email) {
      errorMainSectionClone.push({
        name: 'email',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'email' && item.errorMessage !== ' '
      )
    }
    setErrorMainSection(errorMainSectionClone)
    if (gestionData?.mois_dluo_activate && !gestionData.mois_dluo_input) {
      errorClientGestionClone.push({
        name: 'mois_dluo_input',
        errorMessage: ' ',
      })
    } else {
      errorMainSectionClone = errorMainSectionClone.filter(
        (item) => item.name !== 'mois_dluo_input' && item.errorMessage !== ' '
      )
    }
    setErrorClientGestion(errorClientGestionClone)
    let data: Client = {}
    data.nom = mainSectionData?.nom.trim()
    data.code = mainSectionData?.code.trim()
    data.email = mainSectionData?.email
    data.rupture = gestionData?.rupture
    data.fifo = gestionData?.fifo
    data.fefo = gestionData?.fefo
    data.mois_dluo_activate = gestionData?.mois_dluo_activate
    data.mois_dluo_input = gestionData?.mois_dluo_input
    data.variantes = gestionData?.variantes || false

    if (!isValidAll) return
    if (data.mois_dluo_input && data.mois_dluo_input > 24) return
    if (!initData || !data) return
    if (isValidAll) {
      setIsLoading(true)
      // const dataChange = getTheChangeOfClientData(
      //   initData, data,
      //   [
      //     ['current_sscc_value'],
      //     ['pre_code'],
      //     ['updated_at'],
      //     ['id'],
      //     ['multi_bl'],
      //     ['code_nom'],
      //     ['created_at']
      //   ]
      // );

      data.client_warehouse_company_org = mapIdToTriangles(
        mainSectionDataInit?.client_warehouse_company_org || [],
        mainSectionData?.client_warehouse_company_org || []
      )
      const removedIds = getRemoveTriangles(
        mainSectionDataInit?.client_warehouse_company_org || [],
        mainSectionData?.client_warehouse_company_org || []
      )

      const client = {
        id,
        ...data,
        // updated_at:  Date.now()
      }
      const body = {
        client: client,
        ids: removedIds,
      }
      clientWarehouseApi
        .updateClient(body)
        .then((res) => {
          notification.open({
            className: 'noti noti-success',
            message: (
              <div className="flex items-center">
                <img
                  src={SUCCESS}
                  alt="success"
                  width={50}
                  className="mr-2.5"
                />
                Succès: Client enregistré
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
          setIsLoading(false)
          onCancelModal()
        })
        .catch((e) => {
          if (e.response?.data?.error?.includes('clients_code_key')) {
            const errorMainSectionClone = cloneDeep(errorMainSection)
            errorMainSectionClone.push({
              name: 'code',
              errorMessage: 'Code déjà existant',
            })
            setErrorMainSection(errorMainSectionClone)
          } else
            notification.open({
              className: 'noti noti-error',
              message: (
                <div className="flex items-center">
                  <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                  Une erreur s'est produite. Merci de réessayer ultérieurement
                </div>
              ),
              placement: 'topRight',
              closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
              duration: 3,
            })
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    // default focus
    focusById(clientKey['nom'])
  }, [])

  return (
    <div className="create-section">
      {isLoading && <Loading />}
      <Row className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
        <Col className="flex items-center">
          <ArrowLeftOutlined
            className="text-primary text-2xl"
            onClick={onCancelModal}
          />
          <span className="modal-title-heading-1 ml-3 mr-5">Edition : </span>
          <span className="text-primary text-2xl">Client</span>
        </Col>
      </Row>
      <div
        className="overflow-y-auto main-content "
        style={{ height: '100vh' }}
      >
        <div className="h-8"></div>

        <ClientMainSection
          initData={mainSectionDataInit}
          nonEditData={mainSectionNonEidtOptionData}
          mode={MODE.EDIT}
          onChangeData={onChangeDataMainSection}
          onCheckValid={onCheckValidMainSection}
          mainSectionError={errorMainSection}
        />
        <div className="h-4"></div>
        <Gestion
          disabled
          mode={MODE.EDIT}
          initData={gestionDataInit}
          onChangeData={onChangeGestion}
          clientGestionError={errorClientGestion}
          onCheckValid={onCheckValidGestionSection}
        />
      </div>
      <div
        className={`fixed-bottom flex ${
          isAllowDel ? 'justify-between' : 'justify-end'
        } items-center`}
        style={{ paddingRight: 0 }}
      >
        {isAllowDel && (
          <button
            id={clientKey['Supprimer']}
            data-next-id={clientKey['Sauvegarder']}
            onClick={() => setOpenPopupDelete(true)}
            className="supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg cursor-pointer mr-4 relative"
          >
            {t('deletion')}
          </button>
        )}
        <Button
          id={clientKey['Sauvegarder']}
          data-previous-id={`${clientKey['variante']}, ${clientKey['mois-nbr']}, ${clientKey['mois']}`}
          data-next-id={clientKey['nom']}
          disabled={isLoading}
          className={`btn-submit-modal text-btn-submit-last-modal mr-3`}
          onClick={onSubmit}
        >
          Sauvegarder
        </Button>
      </div>

      <CustomPopup
        title={t('confirmDelete')}
        type={popupType.DELETE}
        isOpen={openPopupDelete}
        onConfirm={onDeleteClient}
        onClose={() => setOpenPopupDelete(false)}
      >
        <p className="text-lg text-[#505050] my-10">{`${t('sureToDelete')} ${t(
          'client'
        )}?`}</p>
      </CustomPopup>
    </div>
  )
}

const showErrorMessage = () => {
  notification.open({
    className: 'noti noti-error',
    message: (
      <div className="flex items-center">
        <img src={ERROR} alt="error" width={50} className="mr-2.5" />
        {t('popupErrorGeneral')}
      </div>
    ),
    placement: 'topRight',
    closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
    duration: 3,
  })
}

export default ClientEdit
