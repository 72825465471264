import { Modal } from 'antd'
import { ReactNode } from 'react'
import { popupType } from '../../../enum/popupType'

interface CustomPopupProps {
  title: string
  children: ReactNode
  type: popupType
  isOpen: boolean
  onConfirm: () => void
  onClose?: () => void
}

/**
 * The @CustomPopup is a component display popup: Confirm, Delete, Save
 * @param props
 * @returns
 */
const CustomPopup = (props: CustomPopupProps) => {
  const { title, children, type, isOpen, onConfirm, onClose } = props

  return (
    <Modal
      open={isOpen}
      width={900}
      onCancel={onClose}
      style={{ zIndex: 100 }}
      closable={type !== popupType.CONFIRM && type !== popupType.VALIDE}
      centered
      data-testid="custom-popup"
      footer={
        <div className="mt-4">
          {type !== popupType.CONFIRM && type !== popupType.VALIDE && (
            <button
              onClick={onClose}
              className="annuler mr-4 border-red border-solid bg-white hover:text-red text-red text-lg font-semibold px-5 py-1 rounded-lg cursor-pointer relative"
            >
              Annuler
            </button>
          )}
          {type === popupType.DELETE ? (
            <button
              onClick={onConfirm}
              className="border-none bg-red text-white hover:text-white text-lg font-semibold  px-5 py-1 rounded-lg cursor-pointer relative"
            >
              Supprimer
            </button>
          ) : (
            <button
              onClick={onConfirm}
              className="border-none bg-[#01E37A] text-white hover:text-white text-lg font-semibold  px-5 py-1 rounded-lg cursor-pointer relative"
            >
              {type === popupType.SAVE
                ? 'Supprimer'
                : type === popupType.VALIDE
                ? 'Valider'
                : 'Confirmer'}
            </button>
          )}
        </div>
      }
    >
      <div className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
        <span className="modal-title-heading-1 mr-1">{title}</span>
      </div>
      {children}
    </Modal>
  )
}

export default CustomPopup
